import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';

const AgathonPage = () => {
  const data = useStaticQuery(graphql`
    query {
      agathon: file(relativePath: { eq: "agathon.jpg" }) {
        ...projectImage
      }
      lambda: file(relativePath: { eq: "agathon-lambda.png" }) {
        ...projectImage
      }
      iot: file(relativePath: { eq: "agathon-iot.png" }) {
        ...projectImage
      }
      desktop: file(relativePath: { eq: "agathon-desktop.png" }) {
        ...projectImage
      }
      android1: file(relativePath: { eq: "agathon-android-1.png" }) {
        ...projectImage
      }
      android2: file(relativePath: { eq: "agathon-android-2.png" }) {
        ...projectImage
      }
      android3: file(relativePath: { eq: "agathon-android-3.png" }) {
        ...projectImage
      }
      android4: file(relativePath: { eq: "agathon-android-4.png" }) {
        ...projectImage
      }
    }
  `);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <h1>Agathon – LiveStatus</h1>
          <ul>
            <li>
              Designed and developed an <strong>IoT solution</strong> that gives
              Agathon real-time insights into hundreds of machines
            </li>
            <li>
              Helped Agathon <strong>launch their product in China</strong>
            </li>
            <li>
              Employed cloud-native <strong>lambda architecture</strong> using{' '}
              <strong>Azure IoT Hub / Stream Analytics</strong> for hot-/cold
              path data processing, MQTT for machine connection
            </li>
            <li>
              Designed (UX) and developed mobile apps and website for real-time
              data using <strong>SignalR</strong>,{' '}
              <strong>Google Firebase</strong>, and Ionic / Angular / TypeScript
            </li>
            <li>
              Published apps to <strong>Apple Store</strong>,{' '}
              <strong>Google Play Store</strong>
            </li>
          </ul>
          <div className="box alt">
            <div className="row gtr-uniform">
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.desktop.childImageSharp.fluid}
                    alt="agathon-desktop"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.lambda.childImageSharp.fluid}
                    alt="agathon-lambda"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.iot.childImageSharp.fluid}
                    alt="agathon-iot"
                  />
                </span>
              </div>
              <div className="col-3">
                <span className="image fit">
                  <Img
                    fluid={data.android1.childImageSharp.fluid}
                    alt="agathon-android"
                  />
                </span>
              </div>
              <div className="col-3">
                <span className="image fit">
                  <Img
                    fluid={data.android2.childImageSharp.fluid}
                    alt="agathon-android"
                  />
                </span>
              </div>
              <div className="col-3">
                <span className="image fit">
                  <Img
                    fluid={data.android3.childImageSharp.fluid}
                    alt="agathon-android"
                  />
                </span>
              </div>
              <div className="col-3">
                <span className="image fit">
                  <Img
                    fluid={data.android4.childImageSharp.fluid}
                    alt="agathon-android"
                  />
                </span>
              </div>
              {/* <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.xplr2.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.xplr3.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.xplr4.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.xplr5.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <Img
                    fluid={data.ucenter.childImageSharp.fluid}
                    alt="ublox-xplr"
                  />
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AgathonPage;
